import { Container } from "reactstrap";
import React from "react";

function Centers() {
  return (
    <>
      <div className="blogs-7">
        <Container>
          <h1>Disclaimer</h1>
          <h3>Achtung, diese Seite dient nur zur Unterhaltungszwecken.</h3>
          <p>
            Es werden keine Produkte werder angeboten noch verkauft. Alle Verwendeten Bilder und
            Inhalte sind entweder <i>lizenzfrei</i> (ohne Quellenangabe), <i>lizenzfrei</i> (mit
            Quellenangabe) oder von uns selbst erstellt. Alle angebotenen Dienst, sind nicht
            öffentlich zugänglich und werden nur von dem Website Eigentümer genutzt. <br />
            <br /> Hilfe und Kontakt:
            <a href="mailto:info@psh.services"> info@psh.services</a>
          </p>
          <div className="space-70"></div>
          <h3>Impressum</h3>
          <p>
            Nach §5 Telemediengesetz (TMG), ist diese Seite zu keinem Impressum verpflichtet, da sie
            ausschließlich zu "persönlichen oder familiären Zwecken" dient.
          </p>
        </Container>
      </div>
    </>
  );
}

export default Centers;
