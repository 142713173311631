/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";

export default function DemoFooter() {
  return (
    <>
      <footer className="footer">
        <Container>
          <Row>
            <Col md="4">
              <h1 className="title">PS • Hosting</h1>
            </Col>
            <Col md="4" xs="6">
              <Nav>
                <NavItem>
                  <NavLink to="/" tag={Link}>
                    Startseite
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="https://gitlab.psh.services">Gitlab</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="https://mail.psh.services">Mail</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="https://cloud.psh.services">Cloud</NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="4" xs="6">
              <Nav>
                <NavItem>
                  <NavLink to="/disclaimer" tag={Link}>
                    Kontakt
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/disclaimer" tag={Link}>
                    Datenschutz
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/disclaimer" tag={Link}>
                    Impressum
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/disclaimer" tag={Link}>
                    Lizenz
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}
