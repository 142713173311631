import React, { useEffect, useState } from "react";

const WheelComponent = ({
  segments,
  segColors,
  winningSegment,
  onFinished,
  onStart,
  onRotate,
  onRotatefinish,
  primaryColor,
  primaryColoraround,
  contrastColor,
  buttonText,
  isOnlyOnce = true,
  size = 290,
  upDuration = 1000,
  downDuration = 100,
  fontFamily = "Arial",
  width = 100,
  height = 100,
  funText = "Du bekommst gar nichts!",
}) => {
  let currentSegment = "";
  let isStarted = false;
  const [isFinished, setFinished] = useState(false);
  let timerHandle = 0;
  const timerDelay = segments.length;
  let angleCurrent = 0;
  let angleDelta = 0;
  let canvasContext = null;
  let maxSpeed = Math.PI / `${segments.length}`;
  const upTime = segments.length * upDuration;
  const downTime = segments.length * downDuration;
  let spinStart = 0;
  let frames = 0;
  const centerX = 300;
  const centerY = 300;
  useEffect(() => {
    wheelInit();
    setTimeout(() => {
      //window.scrollTo(0, 1);
    }, 0);
  }, []);
  const wheelInit = () => {
    initCanvas();
    wheelDraw();
  };
  let maximum = false;

  const initCanvas = () => {
    let canvas = document.getElementById("canvas");
    if (navigator.appVersion.indexOf("MSIE") !== -1) {
      canvas = document.createElement("canvas");
      canvas.setAttribute("width", width);
      canvas.setAttribute("height", height);
      canvas.setAttribute("id", "canvas");
      document.getElementById("wheel").appendChild(canvas);
    }
    canvas.addEventListener("click", spin, false);
    canvasContext = canvas.getContext("2d");
  };

  const spin = () => {
    onStart();
    isStarted = true;
    // onRotate();
    if (timerHandle === 0) {
      spinStart = new Date().getTime();
      // maxSpeed = Math.PI / ((segments.length*2) + Math.random())
      maxSpeed = Math.PI / segments.length;
      frames = 0;
      timerHandle = setInterval(onTimerTick, timerDelay);
    }
  };
  const onTimerTick = () => {
    frames++;
    draw();
    const duration = new Date().getTime() - spinStart;
    let progress = 0;
    let finished = false;
    if (duration < upTime) {
      progress = duration / upTime;
      angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2);
    } else {
      if (winningSegment) {
        if (currentSegment === winningSegment && frames > segments.length) {
          progress = duration / upTime;
          angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
          progress = 1;
        } else {
          progress = duration / downTime;
          angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
        }
      } else {
        progress = duration / downTime;
        if (progress >= 0.8) {
          maximum = true;

          angleDelta = (maxSpeed / 1.2) * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
        } else if (progress >= 0.98) {
          angleDelta = (maxSpeed / 2) * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
        } else angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
      }
      if (progress >= 1) finished = true;
    }

    angleCurrent += angleDelta;
    while (angleCurrent >= Math.PI * 2) angleCurrent -= Math.PI * 2;
    if (finished) {
      setFinished(true);
      onFinished(currentSegment);
      clearInterval(timerHandle);
      timerHandle = 0;
      angleDelta = 0;
    }
  };

  const wheelDraw = () => {
    clear();
    drawWheel();
    drawNeedle();
  };

  const draw = () => {
    clear();
    drawWheel();
    drawNeedle();
  };

  const drawSegment = (key, lastAngle, angle) => {
    const ctx = canvasContext;
    const value = maximum ? funText : segments[key];
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(centerX, centerY);
    ctx.arc(centerX, centerY, size, lastAngle, angle, false);
    ctx.lineTo(centerX, centerY);
    ctx.closePath();
    ctx.fillStyle = segColors[key];
    ctx.fill();
    ctx.stroke();
    ctx.save();
    ctx.translate(centerX, centerY);
    ctx.rotate((lastAngle + angle) / 2);
    ctx.fillStyle = contrastColor || "white";
    ctx.font = "bold 1.5em " + fontFamily;

    let words = value.split(" ");
    const middleIndex = Math.ceil(words.length / 2);

    const firstHalf = words.slice(0, middleIndex)
    const secondHalf = words.slice(middleIndex)



    ctx.fillText(firstHalf.join(" "), size / 2 + 20, -10);
    ctx.fillText(secondHalf.join(" "), size / 2 + 20, 10);
    ctx.restore();
  };

  const drawWheel = () => {
    const ctx = canvasContext;
    let lastAngle = angleCurrent;
    const len = segments.length;
    const PI2 = Math.PI * 2;
    ctx.lineWidth = 1;
    ctx.strokeStyle = primaryColor || "black";
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";
    ctx.font = "1em " + fontFamily;
    for (let i = 1; i <= len; i++) {
      const angle = PI2 * (i / len) + angleCurrent;
      drawSegment(i - 1, lastAngle, angle);
      lastAngle = angle;
    }

    // Draw a center circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, 50, 0, PI2, false);
    ctx.closePath();
    ctx.fillStyle = primaryColor || "black";
    ctx.lineWidth = 5;
    ctx.strokeStyle = contrastColor || "white";
    ctx.fill();
    ctx.font = "bold 2em " + fontFamily;
    ctx.fillStyle = contrastColor || "white";
    ctx.textAlign = "center";
    ctx.fillText(buttonText || "Spin", centerX, centerY + 3);
    ctx.stroke();

    // Draw outer circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, size, 0, PI2, false);
    ctx.closePath();
    ctx.lineWidth = 25;
    ctx.strokeStyle = primaryColoraround || "white";
    ctx.stroke();
  };

  const drawNeedle = () => {
    const ctx = canvasContext;
    ctx.lineWidth = 1;
    ctx.strokeStyle = contrastColor || "white";
    ctx.fileStyle = contrastColor || "white";
    ctx.beginPath();
    ctx.moveTo(centerX + 10, centerY - 50);
    ctx.lineTo(centerX - 10, centerY - 50);
    ctx.lineTo(centerX, centerY - 60);
    ctx.closePath();
    ctx.fill();
    const change = angleCurrent + Math.PI / 2;
    let i = segments.length - Math.floor((change / (Math.PI * 2)) * segments.length) - 1;
    if (i < 0) i = i + segments.length;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = "transparent";
    ctx.font = "bold 1.5em " + fontFamily;
    currentSegment = segments[i];
    isStarted && ctx.fillText(currentSegment, centerX + 10, centerY + size + 50);
  };
  const clear = () => {
    const ctx = canvasContext;
    ctx.clearRect(0, 0, 600, 600);
  };
  return (
    <div id="wheel" className="pt-3">
      <canvas
        id="canvas"
        width="600"
        height="600"
        style={{
          pointerEvents: isFinished && isOnlyOnce ? "none" : "auto",
        }}
      />
    </div>
  );
};
function Wheel() {
  const [count, setCount] = useState(0);
  const [finished, setFinished] = useState(false);
  const segments = [
    "10% Rabatt",
    "1x Server ",
    "2x Server",
    "1x Premium Rolle auf Discord",
    "100% Rabatt auf alles",
  ];
  const segColors = ["#1e4d67", "#0a90dc", "#486c80", "#20485e", "#3DA5E0"];
  const onFinished = (winner) => {
    setFinished(true);
  };

  const motivationText = ["Oh, wow, eine wahre Glanzleistung – du hast tatsächlich gar nichts gewonnen! Wer hätte das gedacht? Aber hey, vielleicht bist du ja einer dieser hartnäckigen Typen, die einfach nicht aufgeben können. Also, warum nicht noch tausend weitere Versuche wagen? Wer weiß, vielleicht findest du eines Tages das Glück – oder auch nicht. Aber hey, Träumen ist erlaubt, oder?",
    "Also komm schon, lass dich nicht von ein paar Niederlagen entmutigen! Du bist ein Kämpfer, ein Träumer, ein unerschütterlicher Optimist! Probiere es noch tausend Mal, weil du nie weißt, welche Überraschungen das Leben für dich bereithält. Wer braucht schon Gewinne, wenn man die Freude am Versuchen hat, richtig?",
    "Also auf geht's, weitermachen! Vielleicht, ganz vielleicht, wirst du irgendwann den glänzenden Jackpot des Lebens treffen – oder eben auch nicht. Aber eines ist sicher: Du wirst jede Menge Erfahrungen sammeln und dabei eine Menge über dich selbst lernen. Also nicht aufgeben, du hast das Zeug dazu, immer wieder aufzustehen und es erneut zu versuchen. Vielleicht wird ja genau das deine größte Belohnung sein – oder eben auch nicht. Aber hey, die Reise ist das Ziel, oder?",
    "Oh, du bist echt nicht zu stoppen, oder? Daumen hoch für deine beeindruckende Ausdauer! Du bist wie eine unaufhaltsame Maschine, die sich nicht von ein paar Rückschlägen beeindrucken lässt. Ich muss sagen, ich bewundere deine Entschlossenheit – obwohl du immer noch nichts gewonnen hast, hast du eine beeindruckende Hartnäckigkeit!",
    "Mach weiter so und lass dich nicht von den vielen \"Nichts\"-Momenten entmutigen. Du bist auf einem Weg des unermüdlichen Strebens nach Erfolg – oder vielleicht auch nur nach einem Hauch von Glück.",
    "Und denk immer daran: Diejenigen, die immer wieder aufstehen und weitermachen, sind die wahren Champions des Lebens. Du hast die Kraft, dich immer wieder herauszufordern, und wer weiß, vielleicht wird deine Ausdauer eines Tages mit einem unerwarteten Triumph belohnt – oder eben auch nicht. Aber hey, die Hoffnung stirbt zuletzt, und du gibst niemals auf!",
    "Also mach dich bereit für tausend weitere Versuche, tausend weitere Möglichkeiten, tausend weitere \"Nichts\"-Ergebnisse – und möglicherweise, nur möglicherweise, auch tausend weitere wertvolle Lektionen auf dem Weg zu deinem persönlichen Erfolg. Halte durch und mach weiter, denn du bist ein wahrer Champion des \"Nichts\"-Gewinnens!",
    "\"Herzlichen Glückwunsch! Du hast unglaubliche [Zahl] Versuche gemacht, aber du hast immer noch gar nichts gewonnen! Ist das nicht beeindruckend? Du denkst wohl, irgendwann wird sich das Blatt wenden, aber wir müssen dich enttäuschen – bei uns gewinnt man einfach nicht. Aber hey, du gibst nicht auf, oder? Wie bewundernswert. Aber du solltest wissen, dass selbst wenn du [ZahlMax] weitere Versuche machst, die Chancen, dass du etwas gewinnst, praktisch gegen Null gehen. Aber du lässt dich nicht entmutigen, oder? Vielleicht, wenn du ein paar Millionen Jahre weitermachst, passiert ja ein Wunder – aber wahrscheinlich auch nicht. Also viel Glück, oder eher, viel Pech bei deinen [ZahlMax] weiteren Versuchen! Du bist wirklich ein Meister des Nicht-Gewinnens!\"".replace("[Zahl]", count.toString()).replace("[ZahlMax]", (1000 - count).toString()),
    ]

  return (
    <div id="wheelCircle" className="mx-auto text-center d-none d-md-block">
      <h1 className="title">Gewinnspiel</h1>
      <div className="sticky-top">
        <WheelComponent
          segments={segments}
          segColors={segColors}
          winningSegment=""
          onFinished={() => {setFinished(true);setCount(prevState => prevState + 1);}}
          onStart={() => {count >= 1001 && setCount(0) && setFinished(false);}}
          primaryColor="#121434"
          primaryColoraround="#ffffff00"
          contrastColor="white"
          buttonText="Dreh!"
          isOnlyOnce={false}
          size={250}
          upDuration={500}
          downDuration={2000}
        />
      </div>
      <div className="container wrapper">
        {finished && (
          motivationText.slice(0, count).map((text) => <h4 className="description">{text}</h4>))}
        {finished && count >= 1000 && "Du hast es geschafft! Du hast 1000 mal nichts gewonnen!"}
      </div>
    </div>
  );
}

export default Wheel;
