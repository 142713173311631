import { Button, Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";

export default function Header() {
  const [style, setStyle] = React.useState({
    [window.innerWidth > window.innerHeight && window.innerWidth > 1615 ? "width" : "height"]:
      "100%",
    position: "absolute",
    zIndex: -1,
  });
  useEffect(() => {
    setStyle({
      [window.innerWidth > window.innerHeight && window.innerWidth > 1615 ? "width" : "height"]:
        "100%",
      position: "absolute",
      zIndex: -1,
    });
    document.title = "Data Center";
  }, [window.innerWidth]);
  return (
    <>
      <div className="page-header header-video header-filter">
        <div className="overlay" />
        <video
          style={style}
          autoPlay="autoplay"
          loop="loop"
          muted="muted"
          playsInline="playsinline"
        >
          <source src={require("assets/video/datacenter.mp4").default} type="video/mp4" />
        </video>
        <Container className="text-center">
          <div className="video-text">
            <h2 className="description">High-End Weltweit</h2>
            <h1 className="title">Unsere Rechenzentren</h1>
            <br />
            <Button
              className="btn-simple btn-neutral btn-round"
              color="default"
              href="#pablo"
              onClick={() => {
                document.body.classList.toggle("fa-bounce");
                document.body.classList.toggle("fa-5x");
              }}
            >
              Mehr lesen <FontAwesomeIcon icon={faChevronDown} shake />
            </Button>
          </div>
        </Container>
        <small>
          <a
            href="https://www.vecteezy.com/video/2656150-concept-of-cloud-data-service-center-animation"
            className="text-white-50 pr-1 d-none d-lg-block"
            style={{ position: "absolute", bottom: "0", right: "0", zIndex: "0" }}
          >
            Concept of cloud data service center animation Stock Videos by Vecteezy
          </a>
        </small>
      </div>
    </>
  );
}
