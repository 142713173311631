import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

function servers() {
  return (
    <>
      <Container>
        <div className="space-100"></div>
        <h1>Unsere Server</h1>
        <p>
          Unsere Server sind, schnell und einzigartig. Unsere Serverstruktur ist zuliebe der Natur
          nicht redundant. Ausgehende Anfragen die auf unsere Server verweisen werden automatisch
          geblockt. Aus diesem Grund zwingen wir uns nur unser internes Netz zu verwenden{" "}
        </p>
        <Row className="align-items-center">
          <Col lg="7">
            <Card className="card-blog card-background" data-animation={true}>
              <div
                className="full-background"
                style={{
                  backgroundImage: "url(" + require("assets/img/servers/rack3.jpg").default + ")",
                }}
              />
              <CardBody>
                <div className="content-bottom">
                  <h6 className="card-category">
                    Neu <br />
                    Hauptmaschine <br /> 64 Kerne <br /> 960 GB Ram <br /> Enterprise SSD's
                  </h6>
                  <CardTitle tag="h3">Wichtig und Richtig</CardTitle>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="5">
            <Card className="card-blog card-background" data-animation={true}>
              <div
                className="full-background"
                style={{
                  backgroundImage: "url(" + require("assets/img/servers/rack1.jpg").default + ")",
                }}
              />
              <CardBody>
                <div className="content-bottom">
                  <h6 className="card-category">
                    Keine Kerne <br /> Kein Ram <br /> Nur Speicher <br /> 100 TB HDD
                  </h6>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <CardTitle tag="h3">Gitlab & API's</CardTitle>
                  </a>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card-blog card-background" data-animation={true}>
              <div
                className="full-background"
                style={{
                  backgroundImage: "url(" + require("assets/img/servers/rack5.jpg").default + ")",
                }}
              />
              <CardBody>
                <div className="content-bottom">
                  <h6 className="card-category">
                    {" "}
                    Email Spam Filter <br /> 1,5 Threads
                  </h6>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <CardTitle tag="h3">Mail und andere Webdienste</CardTitle>
                  </a>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="8">
            <Card className="card-blog card-background" data-animation={true}>
              <div
                className="full-background"
                style={{
                  backgroundImage: "url(" + require("assets/img/servers/drives.jpg").default + ")",
                }}
              />
              <CardBody>
                <div className="content-bottom">
                  <h6 className="card-category">
                    1 Kern <br /> 2 GB Rom
                  </h6>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <CardTitle tag="h3">Server Hosting</CardTitle>
                  </a>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="space-100"></div>
      </Container>
    </>
  );
}

export default servers;
