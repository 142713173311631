export default function Features() {
  return (
    <>
      <div className="wrapper">
        <div className="section">
          <div className="container">
            <h1 className="title">Features</h1>
            <div className="row">
              <div className="col-md-6 ml-auto mr-auto">
                <h2 className="title">Einmal Anmelden</h2>
                <h4 className="description">
                  Das ist unser PSH-Single-Sign-On-System! Keine Passwörter, keine Captchas, keine
                  Registrierungsformulare – einfach unglaublich! Und das Beste: Du wirst nur einmal
                  pro Woche mit Spam bombardiert. Was für ein Glück!
                </h4>
              </div>
              <div className="col-md-6 ml-auto mr-auto">
                <h2 className="title">Uptime</h2>
                <h4 className="description">
                  99,99% Uptime! Nun ja, vielleicht auch 99%... oder 98%... wer zählt schon die
                  Stunden?
                </h4>
              </div>
              <div className="col-md-6 ml-auto mr-auto">
                <h2 className="title">Sicherheit</h2>
                <h4 className="description">
                  Wir nehmen Sicherheit sehr ernst. Wir haben ein Team von Sicherheitsexperten, die
                  24 Stunden am Tag, 7 Tage die Woche, 365 Tage im Jahr arbeiten, um
                  sicherzustellen, dass Ihre Daten sicher sind. Datensicherheit? Keine Sorge, unsere
                  Server sind so gut geschützt, dass nicht einmal wir wissen, wo die Daten sind.
                </h4>
              </div>
              <div className="col-md-6 ml-auto mr-auto">
                <h2 className="title">Sparangebote</h2>
                <h4 className="description">
                  Ausfallzeiten-Roulette – Wähle unseren speziellen Tarif, und du darfst jeden Tag
                  eine Zufallszahl zwischen 1 und 24 wählen, um herauszufinden, wie viele Stunden
                  deine Website heute offline sein wird!
                </h4>
              </div>
              <div className="col-md-6 ml-auto mr-auto">
                <h2 className="title">Unlimited-Storage</h2>
                <h4 className="description">
                  Unbegrenzter Speicherplatz! – Jawohl, du hast richtig gehört! Du kannst so viele
                  Daten auf unseren Servern speichern, wie du möchtest – bis sie alle von unserem
                  tollen Team von Hamstern aufgefressen werden. Unbegrenzt, solange der Vorrat
                  reicht!
                </h4>
              </div>
              <div className="col-md-6 ml-auto mr-auto">
                <h2 className="title">Privatsphäre</h2>
                <h4 className="description">
                  Unser Datenschutzversprechen – Deine persönlichen Daten sind uns so wichtig, dass
                  wir sie sorgfältig an unsere Partner verkaufen und sie auf riesigen Billboards in
                  der ganzen Stadt anzeigen. Gratis-Werbung für dich, was willst du mehr?
                </h4>
              </div>
              <div className="col-md-6 ml-auto mr-auto">
                <h2 className="title">Backup</h2>
                <h4 className="description">
                  Backup im Paralleluniversum – Deine Daten werden in einem Paralleluniversum
                  gespiegelt, wo Zeit und Raum verschmelzen. Eine großartige Möglichkeit, dein
                  Wissen über theoretische Physik zu erweitern, während du verzweifelt versuchst,
                  deine Daten wiederzufinden!
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
