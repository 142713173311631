import { Button, Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export default function Header() {
  return (
    <>
      <div className="page-header header-video header-filter">
        <div className="overlay" />
        <video
          style={{
            [window.innerWidth > window.innerHeight && window.innerWidth > 1615
              ? "width"
              : "height"]: "100%",
            position: "absolute",
            zIndex: -1,
          }}
          autoPlay="autoplay"
          loop="loop"
          muted="muted"
          playsInline="playsinline"
        >
          <source src={require("assets/video/disclaimer.mp4").default} type="video/mp4" />
        </video>
        <Container className="text-center">
          <div className="video-text">
            <h1 className="title">Disclaimer</h1>
            <br />
            <Button
              className="btn-simple btn-neutral btn-round"
              color="default"
              href="#pablo"
              onClick={(e) => {
                document.body.classList.toggle("fa-pulse");
                document.body.classList.toggle("overflow-hidden");
                setTimeout(() => {
                  document.body.classList.remove("fa-pulse");
                  document.body.classList.remove("overflow-hidden");
                }, 10000);
              }}
            >
              Mehr lesen <FontAwesomeIcon icon={faChevronDown} />
            </Button>
          </div>
        </Container>
        <small>
          <a
            className="text-white-50 pr-1 d-none d-lg-block"
            style={{ position: "absolute", bottom: "0", right: "0", zIndex: "0" }}
            href="https://www.vecteezy.com/video/3053418-digital-technology-business-abstract-background"
          >
            Digital technology business abstract background Stock Videos by Vecteezy
          </a>
        </small>
      </div>
    </>
  );
}
