/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Carousel,
  CarouselIndicators,
  CarouselItem,
  Col,
  Row,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFireFlameCurved, faFish, faStar } from "@fortawesome/free-solid-svg-icons";

// core components

export default function Testimonials() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const [isFish, setIsFish] = React.useState(false);
  const [isFire, setIsFire] = React.useState(false);

  const items = [
    {
      content: (
        <Card className="card-testimonial card-plain">
          <div className="card-avatar">
            <a href="#pablo" onClick={(e) => e.preventDefault()}>
              <img
                alt="..."
                className="img img-raised rounded"
                src={`https://gravatar.com/avatar/d3395b06f6a0c9bccdd4db7f1eb2da81?s=200`}
              />
            </a>
          </div>
          <CardBody>
            <h5 className="card-description">
              {`"Danke an das geniale Team. Meine Software läuft jetzt auf meinem Server. Ich habe mich mit der Software vertraut und habe sie auch auf meinem Server installiert. Kenne den Inhaber, habe guten Rabatt bekommen. Ich werde es immer wieder nutzen!"`}
            </h5>
            <CardTitle tag="h4">Pedda</CardTitle>
            <h6 className="category text-muted">Web Designer & Fisch</h6>
            <CardFooter
              onMouseEnter={(e) => setIsFish(true)}
              onMouseLeave={(e) => setIsFish(false)}
            >
              {isFish ? (
                <>
                  <FontAwesomeIcon
                    icon={faFish}
                    spin
                    className="text-warning ml-1"
                    style={{ "--fa-animation-duration": "1s" }}
                  />
                  <FontAwesomeIcon
                    icon={faFish}
                    spin
                    className="text-warning ml-1"
                    style={{ "--fa-animation-duration": "0.5s" }}
                  />
                  <FontAwesomeIcon
                    icon={faFish}
                    spin
                    className="text-warning ml-1"
                    style={{ "--fa-animation-duration": "0.25s" }}
                  />
                  <FontAwesomeIcon
                    icon={faFish}
                    spin
                    className="text-warning ml-1"
                    style={{ "--fa-animation-duration": "0.5s" }}
                  />
                  <FontAwesomeIcon
                    icon={faFish}
                    spin
                    className="text-warning ml-1"
                    style={{ "--fa-animation-duration": "1s" }}
                  />
                </>
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={faStar}
                    bounce
                    className="text-warning ml-1"
                    style={{ "--fa-animation-delay": "0ms" }}
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    bounce
                    className="text-warning ml-1"
                    style={{ "--fa-animation-delay": "200ms" }}
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    bounce
                    className="text-warning ml-1"
                    style={{ "--fa-animation-delay": "400ms" }}
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    bounce
                    className="text-warning ml-1"
                    style={{ "--fa-animation-delay": "600ms" }}
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    bounce
                    className="text-warning ml-1"
                    style={{ "--fa-animation-delay": "800ms" }}
                  />
                </>
              )}
            </CardFooter>
          </CardBody>
        </Card>
      ),
      altText: "",
      caption: "",
      src: "0",
    },
    {
      content: (
        <Card className="card-testimonial card-plain">
          <div className="card-avatar">
            <a href="#pablo" onClick={(e) => e.preventDefault()}>
              <img
                alt="..."
                className="img img-raised rounded bg-dark"
                src={"https://avatars.githubusercontent.com/u/28624736?v=4"}
              />
            </a>
          </div>
          <CardBody>
            <h5 className="card-description">
              {`"Ich bin seit mehreren Jahren stolzer PSH Server Besitzer und ich habe meine Entscheidung nie bereut zu wechseln. Dieses Team ist mega freundlich und hilfsbereit und die Services, die sie anbieten sind auch Perfekt. Eigentlich würde ich gerne eine 10/5 geben, da das leider nicht geht, gibt es nur eine 5/5."`}
            </h5>
            <CardTitle tag="h4">Spark</CardTitle>
            <h6 className="category text-muted">Fullstack Developer</h6>
            <CardFooter
              onMouseEnter={(e) => setIsFire(true)}
              onMouseLeave={(e) => setIsFire(false)}
            >
              {isFire ? (
                <>
                  <FontAwesomeIcon icon={faStar} spin className="text-warning fa-spin ml-1" />
                  <FontAwesomeIcon icon={faStar} spin className="text-warning fa-spin ml-1" />
                  <FontAwesomeIcon icon={faStar} spin className="text-warning fa-spin ml-1" />
                  <FontAwesomeIcon icon={faStar} spin className="text-warning fa-spin ml-1" />
                  <FontAwesomeIcon icon={faStar} spin className="text-warning fa-spin ml-1" />
                  <FontAwesomeIcon icon={faStar} spin className="text-warning fa-spin ml-1" />
                  <FontAwesomeIcon icon={faStar} spin className="text-warning fa-spin ml-1" />
                  <FontAwesomeIcon icon={faStar} spin className="text-warning fa-spin ml-1" />
                  <FontAwesomeIcon icon={faStar} spin className="text-warning fa-spin ml-1" />
                  <FontAwesomeIcon icon={faStar} spin className="text-warning fa-spin ml-1" />
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faStar} spin className="text-warning" />
                  <FontAwesomeIcon icon={faStar} spin className="text-warning ml-1" />
                  <FontAwesomeIcon icon={faStar} spin className="text-warning ml-1" />
                  <FontAwesomeIcon icon={faStar} spin className="text-warning ml-1" />
                  <FontAwesomeIcon icon={faStar} spin className="text-warning ml-1" />
                </>
              )}
            </CardFooter>
          </CardBody>
        </Card>
      ),
      altText: "",
      caption: "",
      src: "1",
    },
    {
      content: (
        <Card className="card-testimonial card-plain">
          <div className="card-avatar">
            <a href="#pablo" onClick={(e) => e.preventDefault()}>
              <img
                alt="..."
                className="img img-raised rounded fa-spin"
                src={require("assets/img/marie.jpg").default}
              />
            </a>
          </div>
          <CardBody>
            <h5 className="card-description">
              Hilfe was mache ich hier?! Ich bin Lost <br />
              <br />
            </h5>
            <CardTitle tag="h4">Tomin</CardTitle>
            <h6 className="category text-muted">Besonderer Kunde</h6>
            <CardFooter>
              <FontAwesomeIcon icon={faStar}  className="text-success" />
              <FontAwesomeIcon icon={faStar}  className="text-success ml-1" />
              <FontAwesomeIcon icon={faStar}  className="text-success ml-1" />
              <FontAwesomeIcon icon={faStar}  className="text-success ml-1" />
              <FontAwesomeIcon icon={faStar}  className="text-success ml-1" />
            </CardFooter>
          </CardBody>
        </Card>
      ),
      altText: "",
      caption: "",
      src: "2",
    },
    {
      content: (
        <Card className="card-testimonial card-plain">
          <div className="card-avatar">
            <a href="#pablo" onClick={(e) => e.preventDefault()}>
              <img
                alt="..."
                className="img img-raised rounded fa-spin"
                src={"https://avatars.githubusercontent.com/u/56088116?v=4"}
              />
            </a>
          </div>
          <CardBody>
            <h5 className="card-description text-justify">
              Wow, ich bin absolut begeistert von diesem Dienst! Wer hätte gedacht, dass man so viel Frustration und Enttäuschung in nur einem Paket bekommen kann? Ich habe mir wirklich gewünscht, meine Zeit und meine Nerven in etwas Sinnloses zu investieren, und dieses Angebot hat meine Erwartungen definitiv übertroffen!
              Das Einloggen war ein echtes Abenteuer, als wäre ich in einem Labyrinth gefangen – aber ohne den Minotaurus. Es gibt einfach keine bessere Möglichkeit, seine Geduld zu testen, als endlos auf eine Antwort von dem "hochqualifizierten" Kundensupport zu warten. Daumen hoch für das grandiose Gefühl der Ungewissheit und des ständigen Fragens: "Wann zum Teufel werde ich endlich eine Lösung für mein Problem bekommen?"
              Die preisgekrönte Funktion, bei der man "Nichts" gewinnt, hat mir eine wahre Achterbahn der Emotionen beschert. Es ist so herzerwärmend zu sehen, wie sich das Rad dreht und dreht und dann – Überraschung! – "Nichts" präsentiert. Einfach umwerfend!
              Und die regelmäßigen Serverabstürze sind die perfekte Möglichkeit, eine geplante Auszeit vom digitalen Leben zu nehmen. Vergiss Yoga und Meditation, hier bekommst du die ultimative Entspannung, wenn du wieder und wieder versuchst, dich anzumelden, aber nur eine Fehlermeldung erhältst!
              Insgesamt kann ich diesen Dienst nur wärmstens empfehlen – für all diejenigen, die einzigartige Erfahrungen suchen, die ihre Ausdauer und ihr Vertrauen in die Menschheit auf die Probe stellen wollen. Es ist wirklich ein Meisterwerk der Fehlfunktionen und des Chaos. Wer braucht schon Funktionalität und Kundenzufriedenheit, wenn man das hier haben kann?
              Viel Spaß beim Verzweifeln und viel Glück, wenn du jemals eine Lösung für deine Probleme finden solltest – aber wahrscheinlich wirst du es nicht. Cheers!" <br />
              <br />
            </h5>
            <CardTitle tag="h4">Meister Meister</CardTitle>
            <h6 className="category text-muted">Will 100x mehr als er brauch</h6>
            <CardFooter>
              <FontAwesomeIcon icon={faStar}  className="text-warning" />
              <FontAwesomeIcon icon={faStar}  className="text-secondary ml-1" />
              <FontAwesomeIcon icon={faStar}  className="text-secondary ml-1" />
              <FontAwesomeIcon icon={faStar}  className="text-secondary ml-1" />
              <FontAwesomeIcon icon={faStar}  className="text-secondary ml-1" />
            </CardFooter>
          </CardBody>
        </Card>
      ),
      altText: "",
      caption: "",
      src: "3",
    }
  ];

  return (
    <>
      <div className="section section-testimonials">
        <Row>
          <Col className="ml-auto mr-auto text-center" md="6">
            <h1 className="title mb-5">Unsere Kunden schätzen uns Wert!</h1>
            <p className="description">
              Unsere Dienstleistungen sind nicht nur ganz besonders gut, sondern auch ganz gut.{" "}
              <strong className="text-warning">2 Web Entwickler</strong> und{" "}
              <strong className="text-warning">3 besondere Personen</strong> sind Kunden bei uns.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="mx-auto" md={4} xs={6}>
            <Carousel activeIndex={activeIndex} interval={1000 * 10}  next={next} previous={previous}>
              <CarouselIndicators
                className="d-none"
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {items.map((item, key) => {
                return (
                  <CarouselItem onExiting={onExiting} onExited={onExited} key={key}>
                    {item.content}
                  </CarouselItem>
                );
              })}
            </Carousel>
          </Col>
          <a
            className="carousel-control-prev"
            data-slide="prev"
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              previous();
            }}
            role="button"
          >
            <i className="tim-icons icon-minimal-left" />
          </a>
          <a
            className="carousel-control-next"
            data-slide="next"
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              next();
            }}
            role="button"
          >
            <i className="tim-icons icon-minimal-right" />
          </a>
        </Row>
      </div>{" "}
    </>
  );
}
