import { Button, Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export default function Header() {
  return (
    <>
      <div className="page-header header-video header-filter">
        <div className="overlay" />
        <video
          style={{
            [window.innerWidth > window.innerHeight && window.innerWidth > 1615
              ? "width"
              : "height"]: "100%",
            position: "absolute",
            zIndex: -1,
          }}
          autoPlay="autoplay"
          loop="loop"
          muted="muted"
          playsInline="playsinline"
        >
          <source src={require("assets/video/server.mp4").default} type="video/mp4" />
        </video>
        <Container className="text-center">
          <div className="video-text">
            <h2 className="description">High-End Weltweit</h2>
            <h1 className="title">Unsere Server</h1>
            <br />
            <Button
              className="btn-simple btn-neutral btn-round"
              color="default"
              href="#pablo"
              onClick={() => {
                document.body.classList.toggle("fa-shake");
                document.body.classList.toggle("fa-2x");
              }}
            >
              Mehr lesen <FontAwesomeIcon icon={faChevronDown} />
            </Button>
          </div>
        </Container>
        <small>
          <a
            className="text-white-50 pr-1 d-none d-lg-block"
            style={{ position: "absolute", bottom: "0", right: "0", zIndex: "0" }}
            href="https://www.vecteezy.com/video/2143038-computer-cpu-with-artificial-intelligence-and-glowing-blue-transfer-of-big-data-in-science-development"
          >
            Computer CPU With Artificial Intelligence and Glowing Blue Transfer of Big Data in
            Science Development Stock Videos by Vecteezy
          </a>
        </small>
      </div>
    </>
  );
}
