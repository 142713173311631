/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  Row,
  UncontrolledCollapse,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGitlab } from "@fortawesome/free-brands-svg-icons";
import {
  faChevronRight,
  faCloud,
  faEnvelope,
  faKey,
  faPlaneDeparture,
  faServer,
  faSpaceShuttle,
} from "@fortawesome/free-solid-svg-icons";

export default function ColorNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  React.useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);
  const changeNavbarColor = () => {
    if (document.documentElement.scrollTop > 299 || document.body.scrollTop > 299) {
      setNavbarColor("navbar-dark");
    } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      setNavbarColor("navbar-transparent");
    }
  };
  return (
    <>
      <Navbar className={"fixed-top " + navbarColor} expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="tooltip6619950104">
              <span>PS •</span> Hosting
            </NavbarBrand>
            <UncontrolledTooltip delay={0} target="tooltip6619950104">
              Gemeinschafts-Hosting von Pedda und Spark
            </UncontrolledTooltip>
            <button className="navbar-toggler" id="navigation">
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <UncontrolledCollapse navbar toggler="#navigation">
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    PS • <span>Hosting</span>
                  </a>
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button className="navbar-toggler" id="navigation">
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle caret color="default" nav>
                  <i className="fa fa-cogs d-lg-none d-xl-none" />
                  Dienstleistungen
                </DropdownToggle>
                <DropdownMenu className="dropdown-with-icons">
                  <DropdownItem href="https://login.psh.services">
                    <FontAwesomeIcon icon={faKey} size="lg" className="mr-1" />
                    PSH-Single-Sign-On
                  </DropdownItem>
                  <DropdownItem href="https://gitlab.psh.services">
                    <FontAwesomeIcon icon={faGitlab} size="lg" className="mr-1" />
                    Gitlab
                  </DropdownItem>
                  <DropdownItem href="https://cloud.psh.services" target="_blank">
                    <FontAwesomeIcon icon={faCloud} size="lg" className="mr-1" />
                    Cloud
                  </DropdownItem>
                  <DropdownItem href="https://mail.psh.services" target="_blank">
                    <FontAwesomeIcon icon={faEnvelope} size="lg" className="mr-1" />
                    Mail
                  </DropdownItem>
                  <DropdownItem href="https://pve.psh.services" target="_blank">
                    <FontAwesomeIcon icon={faServer} size="lg" className="mr-1" />
                    Server
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle caret color="default" nav>
                  <p>Server</p>
                </DropdownToggle>
                <DropdownMenu right className="space-nav">
                  <DropdownItem to="/servers" tag={Link}>
                    <FontAwesomeIcon icon={faChevronRight} size="lg" className="mr-2" />
                    <FontAwesomeIcon icon={faServer} size="lg" className="mr-1" />
                    Server Cluster Main
                  </DropdownItem>
                  <DropdownItem to="/servers" tag={Link}>
                    <FontAwesomeIcon icon={faChevronRight} size="lg" className="mr-2" />
                    <FontAwesomeIcon icon={faServer} size="lg" className="mr-1" />
                    Server Cluster Cloud
                  </DropdownItem>
                  <DropdownItem to="/servers" tag={Link}>
                    <FontAwesomeIcon icon={faChevronRight} size="lg" className="mr-2" />
                    <FontAwesomeIcon icon={faServer} size="lg" className="mr-1" />
                    Server Cluster Mail
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle caret color="default" nav>
                  <p>Rechenzentren</p>
                </DropdownToggle>
                <DropdownMenu right className="space-nav">
                  <DropdownItem to="/datacenters#de" tag={Link}>
                    <FontAwesomeIcon icon={faChevronRight} size="lg" className="mr-1" />
                    <FontAwesomeIcon icon={faPlaneDeparture} size="lg" className="mr-1" />
                    Deutschland
                  </DropdownItem>
                  <DropdownItem to="/datacenters#fn" tag={Link}>
                    <FontAwesomeIcon icon={faChevronRight} size="lg" className="mr-1" />
                    <FontAwesomeIcon icon={faPlaneDeparture} size="lg" className="mr-1" />
                    Finnland
                  </DropdownItem>
                  <DropdownItem to="/datacenters#vc" tag={Link}>
                    <FontAwesomeIcon icon={faChevronRight} size="lg" className="mr-1" />
                    <FontAwesomeIcon icon={faPlaneDeparture} size="lg" className="mr-1" />
                    Vatikan
                  </DropdownItem>
                  <DropdownItem to="/datacenters#ju" tag={Link}>
                    <FontAwesomeIcon icon={faChevronRight} size="lg" className="mr-1" />
                    <FontAwesomeIcon icon={faSpaceShuttle} size="lg" className="mr-1" beatFade />
                    Jupiter
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
}
