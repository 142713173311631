/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Col, Container, Row } from "reactstrap";

export default function Content() {
  return (
    <>
      <div className="section section-content" data-background-color="black">
        <Container>
          <Row>
            <Col lg="6">
              <div className="image-container">
                <img
                  alt="..."
                  className="img img-raised rounded img-blog rellax"
                  data-rellax-speed="4"
                  src={require("assets/img/presentation-page/pve.png").default}
                />
              </div>
            </Col>
            <Col className="ml-auto mr-auto" lg="6">
              <div className="section-description">
                <h1 className="title">Einfache Verwaltung Ihrer Server</h1>
                <h5 className="category text-danger mb-5">
                  <strong>Proxmox VE sei dank</strong>
                </h5>
                <p className="description">
                  Dank unserer leistungsstarken Technologie können Sie Ihre Server, ganz einfach und
                  schnell verwalten. Ob Sie einen neuen Server erstellen oder einen bestehenden
                  Server aktivieren, können Sie diese Aktionen auf einen Klick durchführen. Auch
                  dies ist durch unser intuitives Single Sign On System möglich.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>{" "}
    </>
  );
}
