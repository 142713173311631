/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

export default function Pricing() {
  return (
    <>
      <div className="section section-pricing" id="sectionBuy">
        <Container>
          <Row id="pricing">
            <Col className="ml-auto mr-auto" md="8">
              <Row>
                <Col className="pt-lg-5 mt-lg-5" md="6">
                  <Card className="card-pricing card-plain card-price">
                    <CardBody>
                      <h4 className="title">
                        Kostenlos<small>*</small>
                      </h4>
                      <CardTitle tag="h1">
                        <small>$</small>0
                      </CardTitle>
                      <ul>
                        <li>
                          <b>1</b> Gitlab Projekt
                        </li>
                        <li>
                          <b>1 GB</b> Cloud Storage
                        </li>
                        <li>
                          <b>1</b> Email Account (@psh.services)
                        </li>
                        <li>
                          <b>1</b> Server
                        </li>
                      </ul>
                      <Button
                        className="btn-round"
                        color="info"
                        onClick={() => {
                          window.scroll(document.body.clientWidth, document.body.clientHeight);
                          document.body.classList.toggle("fa-flip-vertical");
                        }}
                      >
                        Jetzt Bestellen
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="pt-lg-5" md="6">
                  <Card className="card-pricing card-plain card-price">
                    <CardBody>
                      <h4 className="title">
                        Kostenlos Plus<small>*</small>
                      </h4>
                      <CardTitle tag="h1">
                        <small>$</small>0
                      </CardTitle>
                      <ul className="b-left">
                        <li>
                          <b>1,5</b> Gitlab Projekte
                        </li>
                        <li>
                          <b>0,5 GB</b> Cloud Storage
                        </li>
                        <li>
                          <b>5+</b> Email Accounts (Eigene Domain)
                        </li>
                        <li>
                          <b>1+</b> Server
                        </li>
                      </ul>
                      <Button
                        className="btn-round"
                        color="info"
                        onClick={() => {
                          document.body.classList.toggle("fa-flip-horizontal");
                        }}
                      >
                        Jetzt Anfragen
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="12" className="text-center mt-4">
                  <small>* Preise inkl. MwSt.</small>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
