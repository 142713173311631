import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

function Centers() {
  return (
    <>
      <div className="blogs-7">
        <Container>
          <h2 className="title">Unsere Standorte</h2>
          <p>Wir haben unsere Server auf der ganzen Welt verteilt</p>
          <Row>
            <Col md="7">
              <Card className="card-blog card-background h-100">
                <div
                  className="full-background"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/datacenters/brandenburger-tor-ge74e897d2_1920.jpg")
                        .default +
                      ")",
                  }}
                />
                <CardBody>
                  <div className="content-center">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <CardTitle tag="h2">Deutschland</CardTitle>
                    </a>
                    <h3 className="card-category">Enterprise Standort</h3>
                    <ul className="w-75 mx-auto text-left">
                      <li>Tier 1 Zertifizierung</li>
                      <li>Zertifiziert nach dem ISO Schichten Modell</li>
                      <li>Brandzuspäterkennung und Gasentfachanlage</li>
                      <li>Biometrische Toilettensysteme</li>
                      <li>12/3 Sicherheitsdienst nicht in Bereitschaft</li>
                      <li>F187-Wände mit Glassicherheitstüren</li>
                      <li>Leistungsarme Stromaggregate</li>
                      <li>keine USV-Anlagen für Umweltschutz</li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="5">
              <Card className="card-blog card-background h-100">
                <div
                  className="full-background"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/datacenters/helsinki-cathedral-g534db2a8e_1920.jpg")
                        .default +
                      ")",
                  }}
                />
                <CardBody>
                  <div className="content-center">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <CardTitle tag="h2">Finnland</CardTitle>
                    </a>
                    <h3 className="card-category">Kalter Standort</h3>
                    <ul className="w-75 mx-auto text-left">
                      <li>Wasser-Installation mit Überdruckschutz und Energieverteilung</li>
                      <li>Wasserversorgung über getrennte Unterverteilungen</li>
                      <li>0 Netzersatzanlagen für das gesamte Gebäude, auch für die Umwelt</li>
                      <li>Klimaanlage für die Mitarbeiter</li>
                      <li>Klimageräte redundant</li>
                      <li>Mitarbeiter werden gut bezahlt</li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md="5">
              <Card className="card-blog card-background h-100">
                <div
                  className="full-background"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/datacenters/tiber-river-g1564ce926_1920.jpg").default +
                      ")",
                  }}
                />
                <CardBody>
                  <div className="content-center">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <CardTitle tag="h2">Vatikan</CardTitle>
                    </a>
                    <h3 className="card-category">Nicht während Feiertagen</h3>
                    <ul className="w-75 mx-auto text-left">
                      <li>Nur Papst und Gott kann auf die Server zugreifen</li>
                      <li>Zugangskontrolle durch Bibelscan</li>
                      <li>Server befinden sich unter der Heiligen Stadt</li>
                      <li>Mönche werden nicht bezahlt</li>
                      <li>Zutrittskontrolle zu den Räumlichkeiten</li>
                      <li>24/7 Zutrittsmöglichkeit</li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="7">
              <Card className="card-blog card-background h-100">
                <div
                  className="full-background"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/datacenters/io-g73a035f5f_1920.jpg").default +
                      ")",
                  }}
                />
                <CardBody>
                  <div className="content-center">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <CardTitle tag="h2">Jupiter</CardTitle>
                    </a>
                    <h3 className="card-category">Nicht mehr weltlich</h3>
                    <ul className="w-75 mx-auto text-left">
                      <li>Server nur mit Außerirdischem erreichbar</li>
                      <li>Zugang bur für allgemeine Raumfahrttechniker</li>
                      <li>
                        Keine Informatik oder Wirtschaftsstudium benötigt, um den Fachkräftemangel
                        auf dem Jupiter entgegenzuwirken
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Centers;
