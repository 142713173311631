/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function PresentationHeader() {
  return (
    <>
      <div className="page-header">
        <img alt="..." className="path" src={require("assets/img/blob.png").default} />
        <img alt="..." className="path2" src={require("assets/img/path2.png").default} />
        <img
          alt="..."
          className="shapes triangle hover_in_place"
          src={require("assets/img/triunghiuri.png").default}
        />
        <img alt="..." className="shapes wave disappear" src={require("assets/img/waves.png").default} />
        <img alt="..." className="shapes squares disappear" src={require("assets/img/patrat.png").default} />
        <img alt="..." className="shapes circle disappear" src={require("assets/img/cercuri.png").default} />
        <Container>
          <div className="content-center brand">
            <h1 className="h1-seo disappear">
              PS • <small className="text-warning">Hosting</small>
            </h1>
            <h3 className="disappear">Unbegrenzte Möglichkeiten - Egal was Sie wollen!</h3>
            <Button
              className="btn-simple mt-4"
              color="warning"
              size="lg"
              onClick={() => {
                let interval = setInterval(function () {
                  window.scroll(
                    getRandomInt(0, document.body.clientWidth),
                    getRandomInt(0, document.body.clientHeight)
                  );
                }, 250);
                setTimeout(() => {
                  document.body.classList.add("fa-spin");
                }, 7500);
                setTimeout(() => {
                  document.body.classList.remove("fa-spin");
                  clearInterval(interval);
                  window.scroll(0, 0);
                }, 15000);
              }}
            >
              Die Möglichkeiten beantragen
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
}
