/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Col, Container, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBellConcierge,
  faChevronRight,
  faFaceLaugh,
  faInfinity,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function Info() {
  return (
    <>
      <Container>
        <Row>
          <Col md="4">
            <div className="info info-hover">
              <div className="icon icon-primary">
                <FontAwesomeIcon icon={faBellConcierge} size="4x" />
              </div>
              <h2 className="info-title">Viele Dienstleistungen</h2>
              <h4 className="description">
                Dazu gehören MailServer, Cloud, Hosting, und vieles mehr...
              </h4>
            </div>
          </Col>
          <Col md="4">
            <div className="info info-hover" style={{ marginTop: "-50px" }}>
              <div className="icon icon-warning">
                <FontAwesomeIcon icon={faInfinity} size="4x" />
              </div>
              <h2 className="info-title">Unbegrenzte Möglichkeiten</h2>
              <h4 className="description">
                Egal was Sie wollen, wir bieten alles an, was Sie brauchen. Wir bieten Ihnen auch
                einige Vorteile an.
              </h4>
            </div>
          </Col>
          <Col md="4">
            <div className="info info-hover">
              <div className="icon icon-success">
                <FontAwesomeIcon icon={faFaceLaugh} size="4x" />
              </div>
              <h2 className="info-title">Freundliche Inhaber</h2>
              <p className="description">
                Unsere Kunden sind die Grundlage für unsere Erfolgsmöglichkeiten. Wir helfen wo wir
                können, um Ihre Ziele zu erreichen.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="text-center">
            <Button color="primary" className="fa-flip-horizontal" to="/datacenters" tag={Link}>
              Zu unseren Rechenzentren <FontAwesomeIcon icon={faChevronRight} size="1x" />
            </Button>
          </Col>
          <Col md={6} className="text-center">
            <Button color="success" className="fa-flip-horizontal" to="/servers" tag={Link}>
              Zu unseren Servern <FontAwesomeIcon icon={faChevronRight} size="1x" />
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
