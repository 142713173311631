/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

// styles
import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-pro-react.scss?v1.2.0";
import "assets/scss/custom.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";

// presentation pages
import Presentation from "views/Presentation.js";
// example pages
import Datacenters from "./views/DataCenter";
import Server from "./views/Server";
import Disclaimer from "./views/Disclaimer";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/home" render={(props) => <Presentation {...props} />} />
      <Route path="/servers" render={(props) => <Server {...props} />} />
      <Route path="/datacenters" render={(props) => <Datacenters {...props} />} />
      <Route path="/disclaimer" render={(props) => <Disclaimer {...props} />} />

      {/*

      <Route path="/index" render={(props) => <Index {...props} />} />

      <Route path="/sections" render={(props) => <Sections {...props} />} />
      <Route path="/about-us" render={(props) => <AboutUs {...props} />} />
      <Route path="/blog-post" render={(props) => <BlogPost {...props} />} />
      <Route path="/blog-posts" render={(props) => <BlogPosts {...props} />} />
      <Route path="/contact-us" render={(props) => <ContactUs {...props} />} />
      <Route path="/landing-page" render={(props) => <LandingPage {...props} />} />
      <Route path="/pricing" render={(props) => <Pricing {...props} />} />
      <Route path="/ecommerce" render={(props) => <Ecommerce {...props} />} />
      <Route path="/product-page" render={(props) => <ProductPage {...props} />} />
      <Route path="/profile-page" render={(props) => <ProfilePage {...props} />} />
      <Route path="/404-error" render={(props) => <Error404 {...props} />} />
      <Route path="/500-error" render={(props) => <Error500 {...props} />} />
      <Route path="/account-settings" render={(props) => <AccountSettings {...props} />} />
      <Route path="/login-page" render={(props) => <LoginPage {...props} />} />
      <Route path="/register-page" render={(props) => <RegisterPage {...props} />} />
      <Route path="/reset-page" render={(props) => <ResetPage {...props} />} />
      <Route path="/invoice-page" render={(props) => <InvoicePage {...props} />} />
      <Route path="/checkout-page" render={(props) => <CheckoutPage {...props} />} />
      <Route path="/chat-page" render={(props) => <ChatPage {...props} />} />
         */}
      <Redirect to="/home" from="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
